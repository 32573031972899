const dev = {
  baseURL: process.env.REACT_APP_PHOTOS_API_ENDPOINT_URL_DEV,
  amplify: {
    Auth: {
      region: process.env.REACT_APP_AWS_REGION_DEV,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID_DEV,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID_DEV,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID_DEV,
    },
    Storage: {
      AWSS3: {
        region: process.env.REACT_APP_AWS_REGION_DEV,
        bucket: process.env.REACT_APP_AWS_S3_BUCKET_DEV,
        multipartUploadFileSizeThreshold: process.env.REACT_APP_MULTIPART_UPLOAD_FILE_SIZE_THRESHOLD,
        maxFileSize: process.env.REACT_APP_MAX_FILE_SIZE,
        fileChunkSize: process.env.REACT_APP_FILE_CHUNK_SIZE
      }
    },
  }
};
// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? dev
  : dev;

export default config;
