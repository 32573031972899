import { post, trackedUpload } from '../utils/fetch';
import config from '../config/aws';
import { to } from 'to-await';
import { Auth } from 'aws-amplify';

const baseURL = config.baseURL;
const MULTIPART_UPLOAD_FILE_SIZE_THRESHOLD = gbToBytes(config.amplify.Storage.AWSS3.multipartUploadFileSizeThreshold);
const MAX_FILE_SIZE = gbToBytes(config.amplify.Storage.AWSS3.maxFileSize);
const FILE_CHUNK_SIZE = gbToBytes(config.amplify.Storage.AWSS3.fileChunkSize);

function gbToBytes(gb) {
  return gb * 1000000000;
}

function normalizeUploadProgress(partsUploadProgress) {
  const partsSummedProgress = partsUploadProgress.reduce((totalProgress, partProgress) => totalProgress + partProgress, 0);
  return partsSummedProgress / partsUploadProgress.length;
}

async function getHeaders() {
  let headers = {
    'Content-type': 'application/json',
  }
  const session = await Auth.currentSession();
  if (session) {
    headers.Authorization = `${session.getIdToken().getJwtToken()}`;
  }
  return headers;
}

const s3Auth = async (data, headers) => post(`${baseURL}/s3Url`, data, headers);

function getMultipartUploadId(data, headers) {
  return post(`${baseURL}/upload-id`, data, headers);
}

function completeMultipartUpload(data, headers) {
  return post(`${baseURL}/complete-upload`, data, headers);
}

function singleUploadToS3(folder, file, callbackProgress) {
  return new Promise( async (resolve, reject) => {
    let error, response, headers;

    if (error) reject(error);

    [error, headers] = await to(getHeaders());

    if (error) {
      reject(error);
    }

    [error, response] = await to(s3Auth({ name: `${folder}/${file.name}`, type: file.type }, headers));
    if (error) reject(error);
    const { uploadURL } = response;

    [error] = await to(trackedUpload(uploadURL, file.slice(0, file.size, file.type), uploadProgress => callbackProgress(uploadProgress * 100)));
    if (error) return reject(error);

    return resolve();
  });
}

async function multipartUploadToS3(folder, file, onUploadProgressChange) {
  let error, headers, response;

  [error, headers] = await to(getHeaders());
  if (error) return new Promise.reject(error);

  const filePath = `${folder}/${file.name}`;
  const fileType = file.type;
  [error, response] = await to(getMultipartUploadId({ filePath, fileType }, headers));
  if (error) return new Promise.reject(error);

  const { uploadId } = response;
  const chunks = [];
  let i;
  for (i = 0; i < file.size; i += FILE_CHUNK_SIZE) {
    if (i + FILE_CHUNK_SIZE < file.size) {
      chunks.push(file.slice(i, i + FILE_CHUNK_SIZE, fileType));
      continue;
    }
    chunks.push(file.slice(i, file.size, fileType));
  }

  const completeUploadPayload = {
    filePath,
    parts: [],
    uploadId
  };

  const totalParts = chunks.length;
  const partsUploadProgress = new Array(totalParts).fill(0);

  [error] = await to(Promise.all(chunks.map(async (chunk, partIndex) => {
    const partNumber = partIndex + 1;
    const presignedUrlPayload = {
      name: filePath,
      type: fileType,
      uploadId,
      partNumber
    };
    const [errGettingUrl, presignedUrlResponse] = await to(s3Auth(presignedUrlPayload, headers));
    if (errGettingUrl) return Promise.reject(errGettingUrl);

    const { uploadURL } = presignedUrlResponse;

    const [errorUploading, uploadResponse] = await to(trackedUpload(uploadURL, chunk, (partProgress) => {
      partsUploadProgress[partIndex] = partProgress;
      const normalizedProgress = normalizeUploadProgress(partsUploadProgress);
      onUploadProgressChange(normalizedProgress * 98);
    }));
    if (errorUploading) return Promise.reject(errorUploading);

    const eTag = uploadResponse.getResponseHeader('ETag'); // We need this header to stitch the file in S3
    completeUploadPayload.parts.push({ eTag, partNumber });
  })));

  [error] = await to(completeMultipartUpload(completeUploadPayload, headers));
  if (error) return new Promise.reject(error);
  onUploadProgressChange(100);

}

export const uploadFileToS3 = (folder: string, file: File, callbackProgress) => {
  const { size } = file;
  if (size > MAX_FILE_SIZE) {
    return Promise.reject(`File too big to upload`);
  }
  if (size <= MULTIPART_UPLOAD_FILE_SIZE_THRESHOLD) {
    return singleUploadToS3(folder, file, callbackProgress);
  }
  return multipartUploadToS3(folder, file, callbackProgress);
}
