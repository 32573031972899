import React, { useState } from 'react';
import './App.css';
import Dropzone from 'react-dropzone';
import { to } from 'to-await';
import { uploadFileToS3 } from './api/s3';
import config from './config/aws';

function App() {
  const [filesList, setFileList] = useState([]);
  const [folder, setFolder] = useState('');
  const [folderError, setFolderError] = useState('');
  const [status, setStatus] = useState('Nenhum arquivo uploaded');
  const [fileProgress, setFileProgress] = useState({});

  function handleChange(event) {
    setFolderError('');
    let { value } = event.target;
    // Note: for now I am considering only one
    // level folder, so any / will be replaced
    value = value.replace('/', '');
    setFolder(value);
  }

  async function handleUpload() {
    if (!folder) { setFolderError('Escreva o nome da pasta 👆🏻'); return; }
    let count = 0;
    for await (let file of filesList) {

      let [error] = await to(uploadFileToS3(folder, file, function(progress) {
        fileProgress[file.name] = progress;
        setFileProgress({...fileProgress});
      }));
      if (error) {
        console.error('error uploading:', file);
      }
      count++;
    }
    setFileList([]);
    setFileProgress({});
    setStatus(`${count} arquivos subidos.`);
  }

  function onFilesDropped(droppedFiles) {
    const files = [...filesList, ...droppedFiles]
    const newProgress = droppedFiles.reduce((acc, curr) => {
      acc[curr.name] = 0;
      return acc;
    }, {});
    setFileProgress({...fileProgress, newProgress});
    setFileList(files);
  }

  function FilesDropped() {
    return (
      <div>
        <span> <b> Status: </b> {status} </span>
        <h3> Arquivos Selecionados </h3>
        {filesList.map(file => {
          return(
            <React.Fragment key={file.name + file.size}>
              <div  className="grid-selected-files">
                <div className={fileProgress[file.name] === 100 ? 'file-completed' : ''}> {file.name} </div>
                <div> {(file.size/1000000).toFixed(2)} Mb </div>
              </div>
              <progress max="100" value={fileProgress[file.name] || 0}></progress>
              <div> {fileProgress[file.name] ? fileProgress[file.name].toFixed(2) : 0}% </div>
            </React.Fragment>
          )
        })}
      </div>
    );
  }

  return (
    <div className="App">
      <h2> Upload arquivos até {config.amplify.Storage.AWSS3.maxFileSize}Gb cada </h2>
      <label htmlFor="folder"> Nome da pasta: </label>
      <input type="text" name="folder" onChange={handleChange} placeholder="aveloz-2020" />
      <span style={{ display: 'block', color: 'red'}}> {folderError} </span>
      <div className="file-drop">
        <Dropzone onDrop={onFilesDropped}>
          {({ getRootProps, getInputProps }) => (
            <section className="file-drop-zone file-drop-section">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="message">Arraste e solte arquivos ou click para selecionar arquivos </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <FilesDropped />
      <button className="button-50" onClick={handleUpload}> UPLOAD </button>
    </div>
  );
}

export default App;
