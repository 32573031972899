import React, { useState, useEffect } from 'react';
import { AuthContext } from './context/auth';
import PrivateRoute from './pages/PrivatePage';
import { Auth } from 'aws-amplify';
import config from './config/aws';
import { to } from 'to-await';
import App from './App';

Auth.configure(config.amplify.Auth);

export default function AuthorizeRoutes() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);

  async function onLoad() {
    let [error, session] = await to(Auth.currentSession());
    if (error) setAuthenticated(false);
    if (session) setAuthenticated(true);
    setIsAuthenticating(false);
  }

  useEffect(() => { onLoad() }, [])

  return (
    !isAuthenticating && <AuthContext.Provider value={{ isAuthenticated, setAuthenticated }}>
      <PrivateRoute exact path="/" >
        <App />
      </PrivateRoute>
    </AuthContext.Provider>
  )
}
