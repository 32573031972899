export async function post(url, data, optionalHeader = {}) {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: optionalHeader,
  });
  if (!response.ok) {
    const error = await response.json();
    throw error;
  }
  const dataRes = await response.json();
  return dataRes;
};

export function trackedUpload(url, data, onUploadProgressChange) {
  return new Promise((resolve, reject) => {

    const request = new XMLHttpRequest();

    request.open('PUT', url);

    request.addEventListener('load', () => resolve(request));
    request.addEventListener('error', e => reject(e));
    request.upload.addEventListener('progress', e => onUploadProgressChange((e.loaded / e.total)));

    request.send(data);
  });
}
