import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { to } from 'to-await';
import { useHistory, useLocation } from 'react-router-dom';

export default function LoginModal() {
  // Storing route history and location
  const history = useHistory();
  const location = useLocation();

  // Setting up state of component
  const [loggingIn, setLoggingStatus] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function handleChange(event) {
    const { name, value } = event.target;
    if (name === 'email') { setEmail(value); }
    if (name === 'password') { setPassword(value); }
  }


  async function handleSubmit(event) {
    event.preventDefault();
    setLoggingStatus(true);
    const [error, auth] = await to(Auth.signIn(email, password));
    setLoggingStatus(false);
    if (error) { console.error('### error', error); }

    // Checking if login succeeded
    if (!error && auth) {
      console.log('loggingIn: ', loggingIn);
      if (location.state && location.state.from) {
        history.push(location.state.from.pathname);
      }
      else {
        history.push('/');
      }
    }
  }

  return (
    <div className="login-page-container">
      <h2> Login </h2>
      <form className="login-form-container" onSubmit={handleSubmit}>
        <label htmlFor="email"> Email </label>
        <input type="email" name="email" required onChange={handleChange}/>
        <label htmlFor="password"> Password </label>
        <input type="password" name="password" required onChange={handleChange}/>
        <button className="btn-style" type="submit"> Login </button>
      </form>
    </div>
  );
}
